import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import fanDir from "../images/fan-directions.webp";
import easyTouch from "../images/easy-touch.webp";
import ashPan from "../images/ash-pan.webp";

import { Link } from "gatsby";

const h1ClassNames = "text-center font-bold text-3xl pb-5";
const h2ClassNames = "italic font-bold text-2xl pt-4";
const h3ClassNames = "italic font-bold text-xl pt-4";
const pClassNames = "pt-4 pl-2 text-lg";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-lg";
const buttonImageClasses = "flex pl-2 pr-2 pt-2 pb-2 w-full ";
const secondaryImageClasses = "flex pl-2 pr-2 pt-2 pb-2 w-4/5 m-auto";
export function Head() {
  return (
    <>
      <title>Overlook Chalet</title>
      <description>Living room temperature</description>
    </>
  );
}
const HvacPage = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <h1 className={h1ClassNames}>Keeping the living room warm</h1>
          <p className={pClassNames}>
            The favorable position that gives the Overlook its fabulous view,
            also means that it's heavily exposed to the cold and wind! Combine
            that with a vaulted ceiling, and it could get pretty chilly in the
            winter! Fortunately we thought through that!
          </p>
          <p className={pClassNames}>
            The best way to keep the living room warm is to use the{" "}
            <b>pellet stove </b>
            and <b>ceiling fan</b>.
          </p>
          <p>
            <br />
            <em>...Ceiling fan?? In the winter?!"</em>
          </p>
          <p className={pClassNames}>
            Believe it or not, the ceiling fan is a critical piece of
            stabilizing the temperature in the living room. Keeping it on will
            push the rising warm air, back down to the living space! Put it on 1
            or 2 and let it rock.
          </p>{" "}
          <h2 className={h2ClassNames}>Enter: the pellet stove</h2>
          <p className={pClassNames}>
            The pellet stove is the best way to produce heat in the house. It's
            completely carbon neutral and forms an air-tight space around the
            flame so there's no risk of sparks or ash escaping! The safety
            features on this thing are unbelievable - you can even leave it
            running while you sleep!
          </p>
          <h3 className={h3ClassNames}>Operating instructions</h3>
          <div className="flex self-center items-center">
            <Link
              target="_blank"
              className="inline-block rounded-sm bg-oc-green-dark font-semibold text-oc-beige pt-1 pb-1 pr-2 pl-2 text-center m-auto border-2 border-oc-green"
              to="https://vimeo.com/1059539203/fc91b1227b"
            >
              Quickstart Video Here!
            </Link>
          </div>
          <p className={pClassNames}>
            It may look intimidating, but this thing is super easy to use. Just
            make sure you{" "}
            <b>
              <em>only use the provided pellets</em>
            </b>{" "}
            in the hopper!
          </p>
          <img
            className={secondaryImageClasses}
            src={easyTouch}
            alt="Touch Screen Instructions"
          />
          <p className={pClassNames}>
            And that's it. The stove will automatically ignite 🔥 A sensor on
            the other side of the room communicates with the stove to maintain
            the desired temperature within 1 degree! The stove will
            automatically adjust its fan speed and the pellet release rate to
            perfectly match the temperature you set.
          </p>
          <h2 className={h2ClassNames}>FAQ & Troubleshooting</h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              <b>Is the pellet stove safe to use?</b>
              <br />
              Absolutely. The stove is designed with extensive safety features
              and can be used without supervision.
            </li>
            <li className={liClassNames}>
              <b>Can we use the fireplace at the same time?</b>
              <br />
              For sure. Traditional fireplaces tend to create a draft and pull
              hot air out of homes (not a commonly known fact) but it's hard to
              beat that ambiance. Light 'er up! Just close the doors and
              reinstall the draft cover when the fireplace cools down.
            </li>
            <li className={liClassNames}>
              <b>What happens if we forget to fill the pellet hopper?</b>
              <br />
              No big deal - the stove will automatically shut off when it runs
              out of pellets. It'll let you know that it's low, however by
              illuminating an LED on the hopper lid when it needs to be
              refilled.
            </li>
            <li className={liClassNames}>
              <b>Does the stove get hot?</b>
              <br />
              Yes - particularly the glass door. Make sure kids steer clear when
              it's burning. We definitely recommend leaving the fire screen in
              place for safety. The hopper lid and touch screen, however, will
              always be perfectly safe to touch.
            </li>
            <li className={liClassNames}>
              <b>Can I use the minisplit unit instead?</b>
              <br />
              Freak what you feel! While this won't be as warm or eco-friendly,
              it's always an option if you're more comfortable with it. We still
              recommend using the fan.
            </li>
            <li className={liClassNames}>
              <b>Can I use the minisplit and stove at the same time?</b>
              <br />
              Technically, yes. We don't recommend it though. If you prefer to
              use the stove during the day and minisplit when you sleep, don't
              worry if there's some overlap during the switch!
            </li>
            <li className={liClassNames}>
              <b>
                HALP! The stove stopped working after I refilled the pellets!
              </b>
              <br />
              You may have left the hopper lid slightly ajar. Make sure you
              close it until it latches, flush with the stove surface and you
              should be good to go!
            </li>
            <li className={liClassNames}>
              <b>Do I need to empty the ashes?</b>
              <br />
              Generally if your stay is 3 days or shorter, you should be good
              without emptying it - we'll take care of it when you leave! If
              however you're staying for longer (so much to explore in the
              Catskills), you may need to empty the ash drawer. You'll see the
              message below if you do.
              <br />
              <Link to="https://youtu.be/dwxRFYPnWb4?t=130">
                <span className="font-bold text-blue-800 text-nowrap">
                  🔗 Here's a quick video on the process - it's super easy.
                </span>
              </Link>
              <img
                className={`${secondaryImageClasses} w-1/3`}
                src={ashPan}
                alt="Empty Ashes Message"
              />
            </li>
            <li className={liClassNames}>
              <b>Which way should the fan rotate?</b>
              <img
                className={buttonImageClasses}
                src={fanDir}
                alt="fan-directions"
              />
            </li>
          </ol>
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default HvacPage;
